import React from 'react';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
}

export const Input: React.FC<InputProps> = ({ className, ...props }) => {
  return (
    <input
      className={`border rounded-lg py-2 px-4 focus:outline-none focus:ring-2 focus:ring-electric-blue ${className}`}
      {...props}
    />
  );
};
