// @ts-nocheck
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Home, BarChart2, LogIn, DollarSign, LogOut, Settings, RefreshCw, User } from 'lucide-react';

interface NavigationProps {
  isLoggedIn: boolean;
  onSignOut: () => void;
  credits: number | null;
  userName: string;
  isAdmin: boolean;
  onRefresh: () => void;
}

const Navigation: React.FC<NavigationProps> = ({
  isLoggedIn,
  onSignOut,
  credits,
  userName,
  isAdmin,
  onRefresh
}) => {
  const location = useLocation();

  const getLinkClass = (path: string) => {
    const baseClasses = "flex items-center gap-2 px-4 py-2 rounded-lg transition-all duration-300";
    const isActive = location.pathname === path;
    
    return isActive
      ? `${baseClasses} bg-emerald-green/10 text-emerald-green font-medium shadow-lg shadow-emerald-green/10`
      : `${baseClasses} text-slate-100 hover:bg-emerald-green/5 hover:text-emerald-green hover:shadow-md`;
  };

  return (
    <div className="fixed top-0 w-full z-50 bg-gradient-to-b from-zinc-900 to-zinc-950 border-b border-zinc-800/50 backdrop-blur-sm shadow-lg shadow-black/20">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          {/* Logo Section */}
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <Link 
                to={isLoggedIn ? '/home' : '/'} 
                className="font-orbitron text-xl font-bold"
              >
                <span className="text-white">Line</span>
                <span className="text-emerald-green">Logic</span>
              </Link>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
              {isLoggedIn && (
                <Link to="/home" className={getLinkClass('/home')}>
                  <Home size={18} />
                  <span>Home</span>
                </Link>
              )}
              
              {isLoggedIn && (
                <Link to="/dashboard" className={getLinkClass('/dashboard')}>
                  <BarChart2 size={18} />
                  <span>Dashboard</span>
                </Link>
              )}
              
              <Link to="/pricing" className={getLinkClass('/pricing')}>
                <DollarSign size={18} />
                <span>Pricing</span>
              </Link>
            </div>
          </div>

          {/* Actions Section */}
          <div className="flex items-center gap-2">
            {isLoggedIn ? (
              <>
                <div className="flex items-center gap-4">
                  {/* User Info */}
                  <div className="flex items-center gap-2 px-4 py-2 bg-zinc-800/50 rounded-lg border border-zinc-700/50">
                    <User size={16} className="text-zinc-400" />
                    <span className="text-zinc-300">{userName}</span>
                  </div>
                  
                  {/* Credits */}
                  <div className="px-4 py-2 bg-zinc-800/50 rounded-lg border border-zinc-700/50">
                    <span className="text-zinc-300">Credits: </span>
                    <span className="text-emerald-green font-medium">
                      {credits !== null ? credits : 'Loading...'}
                    </span>
                  </div>
                </div>
                
                <button
                  onClick={onRefresh}
                  className="flex items-center gap-2 p-2 rounded-lg text-zinc-300 hover:text-emerald-green hover:bg-emerald-green/5 transition-all duration-300"
                  title="Refresh"
                >
                  <RefreshCw size={18} />
                </button>
                
                {isAdmin && (
                  <Link to="/admin" className={getLinkClass('/admin')}>
                    <Settings size={18} />
                    <span>Admin</span>
                  </Link>
                )}
                
                <button
                  onClick={onSignOut}
                  className="flex items-center gap-2 px-4 py-2 rounded-lg text-zinc-300 hover:text-red-400 hover:bg-red-400/5 transition-all duration-300"
                >
                  <LogOut size={18} />
                  <span>Sign Out</span>
                </button>
              </>
            ) : (
              <Link 
                to="/signin" 
                className="flex items-center gap-2 px-4 py-2 rounded-lg bg-emerald-green text-white hover:bg-emerald-green/90 transition-all duration-300 shadow-lg shadow-emerald-green/20"
              >
                <LogIn size={18} />
                <span>Sign In</span>
              </Link>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;