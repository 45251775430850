import React, { createContext, useContext, useState, ReactNode } from 'react';
import axios from 'axios';

interface User {
  uid: string;
  email: string;
}

interface AuthContextType {
  user: User | null;
  credits: number;
  login: (user: User) => void;
  logout: () => void;
  updateCredits: (newCredits: number) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [credits, setCredits] = useState<number>(0);

  const login = async (user: User) => {
    setUser(user);
    try {
      const response = await axios.get(`/api/getUserCredits?userId=${user.uid}`);
      const userCredits = response.data.credits;
      setCredits(userCredits);
    } catch (error) {
      console.error('Error fetching user credits:', error);
      setCredits(0);
    }
  };

  const logout = () => {
    setUser(null);
    setCredits(0);
  };

  const updateCredits = (newCredits: number) => {
    setCredits(newCredits);
  };

  return (
    <AuthContext.Provider value={{ user, credits, login, logout, updateCredits }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
