import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import { Button } from '@/components/ui/Button';
import { Input } from '@/components/ui/Input';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Bot, AlertCircle, Zap, History, CreditCard } from 'lucide-react';
import { Alert, AlertDescription } from '@/components/ui/alert';
import AnimatedBackground from '@/components/ui/AnimatedBackground';
import { motion } from 'framer-motion';
import { format } from 'date-fns';

interface TestResult {
  account: string;
  queuePower: number;
  marketInfluence: number;
  systemOptimization: number;
  queueHealth: number;
  positionStrength: number;
  estimatedPosition: number;
  responseTime: number;
  industryTrends: {
    averageQueueTime: number;
    ticketDemandIndex: number;
  };
  technologyScore: number;
  timestamp: Date;
}

interface RecentTest {
  id: string;
  account: string;
  timestamp: Date;
  queuePower: number;
  estimatedPosition: number;
}

interface HomePageProps {
  credits: number;
}

const testAccount = async (userId: string, account: string, userData: any): Promise<TestResult> => {
  console.log('Starting testAccount function');
  console.log('Parameters:', { userId, account, userData });
  try {
    console.log('Making API request to testAccount function');
    const response = await fetch('/.netlify/functions/testAccount', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId,
        account,
        userData
      })
    });
    console.log('API response status:', response.status);
    console.log('API response headers:', Object.fromEntries(response.headers.entries()));
    const data = await response.json();
    console.log('API response data:', data);
    if (!response.ok) {
      console.error('API error:', data.error);
      throw new Error(data.error || 'API request failed');
    }
    if (!data.success) {
      console.error('API returned success: false:', data.error);
      throw new Error(data.error || 'Unknown error occurred');
    }
    if (!data.data) {
      console.error('API response missing data property:', data);
      throw new Error('API response missing data');
    }
    // Validate the response data structure
    const requiredFields = [
      'account',
      'queuePower',
      'marketInfluence',
      'systemOptimization',
      'queueHealth',
      'positionStrength',
      'estimatedPosition',
      'responseTime',
      'technologyScore',
      'industryTrends'
    ];
    const missingFields = requiredFields.filter(field => !data.data.hasOwnProperty(field));
    if (missingFields.length > 0) {
      console.error('API response missing required fields:', missingFields);
      throw new Error(`API response missing required fields: ${missingFields.join(', ')}`);
    }
    if (!data.data.industryTrends || 
        typeof data.data.industryTrends.averageQueueTime === 'undefined' ||
        typeof data.data.industryTrends.ticketDemandIndex === 'undefined') {
      console.error('API response missing industryTrends data:', data.data.industryTrends);
      throw new Error('API response missing industryTrends data');
    }
    console.log('Successfully validated API response data');
    return data.data;
  } catch (error) {
    console.error('Error in testAccount function:', error);
    throw error;
  }
};

const HomePage: React.FC<HomePageProps> = ({ credits }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [accounts, setAccounts] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [recentTests, setRecentTests] = useState<RecentTest[]>([]);
  const [progress, setProgress] = useState({ current: 0, total: 0 });
  const [results, setResults] = useState<TestResult[]>([]);
  const [isBulkMode, setIsBulkMode] = useState(false);

  useEffect(() => {
    const fetchRecentTests = async () => {
      if (!user?.uid) return;
      
      try {
        console.log('Fetching recent tests for user:', user.uid);
        const response = await fetch('/.netlify/functions/getRecentTests', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: user.uid }),
        });
        
        if (!response.ok) throw new Error('Failed to fetch recent tests');
        
        const data = await response.json();
        setRecentTests(data.tests);
      } catch (error) {
        console.error('Error fetching recent tests:', error);
      }
    };

    fetchRecentTests();
  }, [user?.uid]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form submitted');
    
    if (!accounts.trim()) {
      setError('Please enter an account to test');
      return;
    }
    if (!user) {
      setError('Please log in to test accounts');
      return;
    }

    const accountList = isBulkMode 
      ? accounts.split('\n').map(acc => acc.trim()).filter(acc => acc)
      : [accounts.trim()];
    
    if (credits < accountList.length) {
      setError(`Insufficient credits. You need ${accountList.length} credit${accountList.length > 1 ? 's' : ''} to test ${accountList.length} account${accountList.length > 1 ? 's' : ''}.`);
      return;
    }

    setIsLoading(true);
    setError(null);
    setProgress({ current: 0, total: accountList.length });
    setResults([]);

    try {
      const allResults: TestResult[] = [];
      
      for (let i = 0; i < accountList.length; i++) {
        const account = accountList[i];
        setProgress(prev => ({ ...prev, current: i + 1 }));
        
        try {
          console.log(`Testing account ${i + 1}/${accountList.length}:`, account);
          const result = await testAccount(user.uid, account, {
            credits: credits,
            email: user.email
          });
          allResults.push(result);
        } catch (error) {
          console.error(`Error testing account ${account}:`, error);
          allResults.push({
            account,
            error: error instanceof Error ? error.message : 'Unknown error',
            failed: true
          } as any);
        }
      }
      
      if (allResults.length === 1 && !allResults[0].failed) {
        navigate('/results', { 
          state: { 
            results: allResults[0],
            from: 'home'
          },
          replace: false 
        });
      } else {
        navigate('/results', { 
          state: { 
            results: allResults,
            from: 'home'
          },
          replace: false 
        });
      }
    } catch (error) {
      console.error('Test failed:', error);
      setError(error instanceof Error ? error.message : 'An error occurred while testing the accounts');
    } finally {
      setIsLoading(false);
      setProgress({ current: 0, total: 0 });
    }
  };

  return (
    <div className="relative min-h-screen">
      <div className="absolute inset-0 overflow-hidden">
        <AnimatedBackground lineColor="subtle-green" />
      </div>
      <div className="relative z-10">
        <div className="container mx-auto px-4 py-12">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="max-w-4xl mx-auto"
          >
            <div className="text-center mb-12">
              <h1 className="text-4xl font-bold bg-gradient-to-r from-emerald-400 to-blue-500 bg-clip-text text-transparent mb-4">
                Queue Testing Dashboard
              </h1>
              <p className="text-gray-400 text-lg">
                Test your accounts and get instant insights
              </p>
            </div>

            <Card className="bg-gradient-to-br from-white/[0.12] to-white/[0.06] backdrop-blur-xl border border-blue-500/30 shadow-2xl mb-8">
              <CardHeader>
                <div className="flex justify-between items-center">
                  <div>
                    <CardTitle className="flex items-center gap-2">
                      <Bot className="h-6 w-6 text-emerald-500" />
                      Test Your Account{isBulkMode ? 's' : ''}
                    </CardTitle>
                    <CardDescription>
                      {isBulkMode 
                        ? "Enter multiple accounts to test (one per line)"
                        : "Enter an account to analyze its queue performance"
                      }
                    </CardDescription>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-gray-600">Bulk Mode</span>
                    <button
                      onClick={() => {
                        setAccounts('');
                        setIsBulkMode(!isBulkMode);
                      }}
                      className={`
                        relative inline-flex h-6 w-11 items-center rounded-full
                        transition-colors focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2
                        ${isBulkMode ? 'bg-emerald-500' : 'bg-gray-200'}
                      `}
                    >
                      <span
                        className={`
                          inline-block h-4 w-4 transform rounded-full bg-white transition-transform
                          ${isBulkMode ? 'translate-x-6' : 'translate-x-1'}
                        `}
                      />
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardContent>
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div className="space-y-2">
                    {isBulkMode ? (
                      <textarea
                        value={accounts}
                        onChange={(e) => setAccounts(e.target.value)}
                        className="w-full h-32 px-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500"
                        placeholder="Enter accounts (one per line)&#10;example1@domain.com&#10;example2@domain.com"
                        disabled={isLoading}
                      />
                    ) : (
                      <Input
                        type="text"
                        placeholder="Enter account to test"
                        value={accounts}
                        onChange={(e) => setAccounts(e.target.value)}
                        disabled={isLoading}
                      />
                    )}
                  </div>

                  {error && (
                    <Alert variant="destructive">
                      <AlertCircle className="h-4 w-4" />
                      <AlertDescription>{error}</AlertDescription>
                    </Alert>
                  )}

                  {progress.total > 0 && (
                    <div className="space-y-2">
                      <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                        <div
                          className="h-full bg-emerald-500 transition-all duration-300"
                          style={{ width: `${(progress.current / progress.total) * 100}%` }}
                        />
                      </div>
                      <p className="text-sm text-gray-600">
                        Testing account {progress.current} of {progress.total}
                      </p>
                    </div>
                  )}

                  <Button
                    type="submit"
                    className="w-full"
                    disabled={isLoading || !accounts.trim()}
                  >
                    {isLoading ? (
                      <span className="flex items-center gap-2">
                        <Bot className="h-4 w-4 animate-spin" />
                        Testing {isBulkMode ? 'Accounts' : 'Account'}...
                      </span>
                    ) : (
                      <span className="flex items-center gap-2">
                        <Zap className="h-4 w-4" />
                        Test {isBulkMode ? 'Accounts' : 'Account'}
                      </span>
                    )}
                  </Button>

                  <p className="text-sm text-center text-gray-600">
                    {credits} credits remaining
                  </p>
                </form>
              </CardContent>
            </Card>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <Card className="bg-gradient-to-br from-white/[0.12] to-white/[0.06] backdrop-blur-xl border border-blue-500/30 shadow-xl">
                <CardHeader>
                  <CardTitle className="text-white flex items-center gap-2">
                    <History className="h-5 w-5 text-emerald-400" />
                    Recent Tests
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    {recentTests.length > 0 ? (
                      recentTests.map((test, index) => (
                        <motion.div
                          key={test.id}
                          initial={{ opacity: 0, x: -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ delay: index * 0.1 }}
                          className="p-4 bg-white/5 rounded-lg hover:bg-white/10 transition-all cursor-pointer group"
                          onClick={() => navigate('/results', { state: { results: test } })}
                        >
                          <div className="flex justify-between items-center">
                            <div className="space-y-1">
                              <span className="text-white group-hover:text-emerald-400 transition-colors">
                                {test.account}
                              </span>
                              <div className="flex items-center gap-2 text-xs text-gray-400">
                                <span>Queue Power: {test.queuePower}/100</span>
                                <span>•</span>
                                <span>Position: #{test.estimatedPosition}</span>
                              </div>
                            </div>
                            <div className="text-right">
                              <div className="text-gray-400 text-sm">
                                {format(new Date(test.timestamp), 'MMM d, yyyy')}
                              </div>
                              <div className="text-xs text-gray-500">
                                {format(new Date(test.timestamp), 'h:mm a')}
                              </div>
                            </div>
                          </div>
                        </motion.div>
                      ))
                    ) : (
                      <div className="text-gray-400 text-center py-8">
                        <div className="mb-3">
                          <History className="h-12 w-12 mx-auto text-gray-500 opacity-50" />
                        </div>
                        <p className="text-sm">No recent tests</p>
                        <p className="text-xs text-gray-500 mt-1">
                          Your test history will appear here
                        </p>
                      </div>
                    )}
                  </div>
                </CardContent>
              </Card>

              <Card className="bg-gradient-to-br from-white/[0.12] to-white/[0.06] backdrop-blur-xl border border-blue-500/30 shadow-xl">
                <CardHeader>
                  <CardTitle className="text-white flex items-center gap-2">
                    <Zap className="h-5 w-5 text-emerald-400" />
                    Quick Stats
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    <div className="p-4 bg-white/5 rounded-lg">
                      <div className="flex justify-between items-center">
                        <span className="text-gray-400">Total Tests</span>
                        <span className="text-white font-bold">{recentTests.length}</span>
                      </div>
                    </div>
                    <div className="p-4 bg-white/5 rounded-lg">
                      <div className="flex justify-between items-center">
                        <span className="text-gray-400">Credits Available</span>
                        <span className="text-white font-bold">{credits}</span>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;