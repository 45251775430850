import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { User as FirebaseUser, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot, getDoc, collection } from 'firebase/firestore';
import { db, auth } from './firebaseConfig';

// Component imports
import Navigation from './components/Navigation';
import { AuthProvider } from './context/AuthContext';
import HomePage from './components/HomePage';
import Dashboard from './components/Dashboard';
import SignInPage from './components/SignInPage';
import PricingPage from './components/PricingPage';
import ResultsPage from './components/ResultsPage';
import AdminPage from './components/AdminPage';
import LoadingScreen from './components/LoadingScreen';
import ErrorBoundary from './components/ErrorBoundary';
import LandingPage from './components/LandingPage';
import AnimatedBackground from './components/ui/AnimatedBackground';

interface UserData {
  credits?: number;
  [key: string]: any;
}

const ADMIN_UIDS = ['auLPlUj6zMeTecbiW8dw0FL0j983', 'KGYaVKweyqSvG14NmJE94swGbC12'];

const App: React.FC = () => {
  const [user, setUser] = useState<FirebaseUser | null>(null);
  const [credits, setCredits] = useState<number>(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [userName, setUserName] = useState<string>('');
  const location = useLocation();

  // Determine if we should show the navigation
  const shouldShowNavigation = !['/', '/signin', '/signup'].includes(location.pathname);

  useEffect(() => {
    let unsubscribeSnapshot: (() => void) | undefined;

    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        // Initially set name from Firebase Auth
        setUserName(user.displayName || user.email || '');
        setIsAdmin(ADMIN_UIDS.includes(user.uid));

        try {
          // Get user document from Firestore
          const userDocRef = doc(collection(db, 'Users'), user.uid);
          const userDoc = await getDoc(userDocRef);
          
          if (userDoc.exists()) {
            const userData = userDoc.data();
            // Update name from Firestore if available
            if (userData.name) {
              setUserName(userData.name);
            }
            setCredits(userData.credits || 0);
          }

          // Set up real-time listener for user document
          unsubscribeSnapshot = onSnapshot(userDocRef, (doc) => {
            if (doc.exists()) {
              const userData = doc.data();
              if (userData.name) {
                setUserName(userData.name);
              }
              setCredits(userData.credits || 0);
            }
          });
        } catch (error) {
          console.error('Error fetching user data:', error);
          setError('Failed to fetch user data. Please try again later.');
        }
      } else {
        // User is signed out
        setUser(null);
        setCredits(0);
        setIsAdmin(false);
        setUserName('');
      }
      setIsLoading(false);
    });

    return () => {
      unsubscribeAuth();
      if (unsubscribeSnapshot) {
        unsubscribeSnapshot();
      }
    };
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
      console.error('Error signing out:', error);
      setError(`Failed to sign out: ${errorMessage}`);
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  if (isLoading) {
    return (
      <>
        <AnimatedBackground gradientStyle="green" />
        <LoadingScreen />
      </>
    );
  }

  if (error) {
    return (
      <>
        <AnimatedBackground gradientStyle="green" />
        <div className="relative flex items-center justify-center min-h-screen">
          <div className="p-6 max-w-sm mx-auto bg-navy/90 backdrop-blur-sm rounded-xl shadow-md border border-electric-blue">
            <h2 className="text-xl font-bold text-red-500 mb-2">Error</h2>
            <p className="text-soft-gray">{error}</p>
            <button
              onClick={handleRefresh}
              className="mt-4 px-4 py-2 bg-electric-blue text-white rounded hover:bg-blue-600 transition-colors"
            >
              Retry
            </button>
          </div>
        </div>
      </>
    );
  }

  return (
    <AuthProvider>
      <ErrorBoundary>
        <div className="relative min-h-screen">
          <AnimatedBackground gradientStyle="green" />
          {shouldShowNavigation && (
            <Navigation 
              isLoggedIn={!!user}
              onSignOut={handleSignOut}
              credits={credits}
              userName={userName}
              isAdmin={isAdmin}
              onRefresh={handleRefresh}
            />
          )}
          <main className={`relative ${shouldShowNavigation ? 'pt-16' : ''}`}>
            <Routes>
              <Route 
                path="/" 
                element={user ? <Navigate to="/dashboard" replace /> : <LandingPage />}
              />
              <Route 
                path="/signin" 
                element={
                  !user ? (
                    <SignInPage />
                  ) : (
                    <Navigate to="/dashboard" replace />
                  )
                } 
              />
              <Route 
                path="/dashboard" 
                element={
                  user ? (
                    <Dashboard 
                      userId={user.uid}
                      credits={credits}
                      isAdmin={isAdmin}
                      userName={userName}
                    />
                  ) : (
                    <Navigate to="/signin" replace />
                  )
                }
              />
              <Route 
                path="/home" 
                element={
                  user ? (
                    <HomePage 
                      testAccount={async (account: string) => {
                        try {
                          console.log('Testing account functionality for account:', account);
                          const userDocRef = doc(db, 'Users', account);
                          const snapshot = await getDoc(userDocRef);
                          if (snapshot.exists()) {
                            const userData = snapshot.data() as UserData;
                            console.log('Test account data:', userData);
                          } else {
                            throw new Error('Test account not found');
                          }
                        } catch (error) {
                          const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
                          console.error('Test account error:', error);
                          throw new Error(`Test account error: ${errorMessage}`);
                        }
                      }} 
                      isLoggedIn={!!user}
                      credits={credits}
                      userId={user.uid}
                    />
                  ) : (
                    <Navigate to="/signin" replace />
                  )
                } 
              />
              <Route 
                path="/pricing" 
                element={
                  <PricingPage 
                    onSignOut={handleSignOut}
                    credits={credits}
                    userName={userName}
                    isAdmin={isAdmin}
                    onRefresh={handleRefresh}
                  />
                } 
              />
              <Route 
                path="/results" 
                element={
                  user ? (
                    <ResultsPage userId={user.uid} />
                  ) : (
                    <Navigate to="/signin" replace />
                  )
                }
              />
              <Route 
                path="/admin" 
                element={
                  isAdmin ? (
                    <AdminPage />
                  ) : (
                    <Navigate to="/dashboard" replace />
                  )
                }
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </main>
        </div>
      </ErrorBoundary>
    </AuthProvider>
  );
};

export default App;