import React from 'react'

const AnimatedDivider: React.FC<BaseProps> = () => {
  return (
    <div className="w-full flex justify-center py-8 px-4">
      <div className="relative w-96 h-0.5 overflow-hidden">
        {/* Base divider line */}
        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-emerald-400/30 to-transparent" />

        {/* Light effect */}
        <div className="absolute inset-0">
          <div
            className="absolute top-0 h-full animate-sweep"
            style={{
              width: '5px',
              background: 'rgba(72, 255, 167, 0.4)',
              filter: 'blur(2.5px)',
              left: '0',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default AnimatedDivider
