// src/lib/firebase.ts
import { initializeApp, FirebaseApp } from "firebase/app";
import { getAuth, Auth } from "firebase/auth";
import { getFirestore, Firestore, Timestamp } from "firebase/firestore";
import { getFunctions, Functions } from "firebase/functions";
import { getAnalytics, Analytics } from "firebase/analytics";

// Type definitions
export interface CreditHistoryEntry {
  amount: number;
  timestamp: string | Date | Timestamp;
  type: 'add' | 'use';
}

export interface User {
  id: string;
  email: string;
  credits: number;
  lastLogin: Date | Timestamp;
  lastLoginAt: string | Timestamp;
  creditHistory?: CreditHistoryEntry[];
}

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

// Validate environment variables
const requiredEnvVars = [
  'VITE_FIREBASE_API_KEY',
  'VITE_FIREBASE_AUTH_DOMAIN',
  'VITE_FIREBASE_PROJECT_ID',
  'VITE_FIREBASE_STORAGE_BUCKET',
  'VITE_FIREBASE_MESSAGING_SENDER_ID',
  'VITE_FIREBASE_APP_ID',
  'VITE_FIREBASE_MEASUREMENT_ID'
] as const;

// Check for missing environment variables
const missingVars = requiredEnvVars.filter(varName => !import.meta.env[varName]);
if (missingVars.length > 0) {
  throw new Error(`Missing environment variables: ${missingVars.join(', ')}`);
}

const firebaseConfig: FirebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase services
let app: FirebaseApp;
let auth: Auth;
let db: Firestore;
let functions: Functions;
let analytics: Analytics;

try {
  console.log('Initializing Firebase with config:', {
    ...firebaseConfig,
    apiKey: '***' // Hide API key in logs
  });
  
  // Check if Firebase is already initialized
  try {
    app = initializeApp(firebaseConfig);
  } catch (error) {
    const e = error as Error & { code?: string };
    if (e.code === 'app/duplicate-app') {
      console.log('Firebase app already initialized, getting existing app');
      app = initializeApp();
    } else {
      throw error;
    }
  }
  
  auth = getAuth(app);
  db = getFirestore(app);
  functions = getFunctions(app);
  analytics = getAnalytics(app);

  // Set up auth state listener
  auth.onAuthStateChanged(
    (user) => {
      if (user) {
        console.log('User signed in:', user.uid);
      } else {
        console.log('User signed out');
      }
    },
    (error) => {
      console.error('Auth state change error:', error);
    }
  );

} catch (error) {
  const typedError = error as Error & { code?: string };
  console.error('Firebase initialization error:', {
    name: typedError.name,
    message: typedError.message,
    code: typedError.code,
    stack: typedError.stack
  });
  throw error;
}

// Export initialized services and types
export {
  auth,
  db,
  functions,
  analytics
};