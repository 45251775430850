import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Check as CheckIcon, Heart, Sparkles, Rocket, Crown } from 'lucide-react';
import AnimatedBackground from '@/components/ui/AnimatedBackground';
import Navigation from '@/components/Navigation';
interface PricingPageProps {
  onSignOut: () => void;
  credits: number;
  userName: string;
  isAdmin: boolean;
  onRefresh: () => void;
}
const PricingPage: React.FC<PricingPageProps> = ({ onSignOut, credits, userName, isAdmin, onRefresh }) => {
  const navigate = useNavigate();
  const plans = [
    {
      name: 'Starter Pack',
      icon: Sparkles,
      credits: 50,
      price: 62.50,
      pricePerTest: 1.25,
      description: 'Perfect for getting started with queue analysis',
      features: [
        '50 test credits',
        '$1.25 per test',
        'Basic queue analysis',
        'Standard support',
        'Basic reporting',
        'Email notifications'
      ],
      color: 'from-blue-500 to-indigo-500',
      hoverColor: 'hover:from-blue-600 hover:to-indigo-600',
      textColor: 'text-blue-400'
    },
    {
      name: 'Growth Pack',
      icon: Rocket,
      credits: 100,
      price: 125,
      pricePerTest: 1.25,
      description: 'For teams starting to scale',
      features: [
        '100 test credits',
        '$1.25 per test',
        'Advanced queue analysis',
        'Priority support',
        'Advanced reporting'
      ],
      color: 'from-emerald-500 to-cyan-500',
      hoverColor: 'hover:from-emerald-600 hover:to-cyan-600',
      textColor: 'text-emerald-400'
    },
    {
      name: 'Pro Pack',
      icon: Rocket,
      credits: 250,
      price: 225,
      pricePerTest: 0.90,
      description: 'Most popular for growing businesses',
      features: [
        '250 test credits',
        '$0.90 per test',
        'Advanced queue analysis',
        'Priority support',
        'Advanced reporting',
        'Custom integrations'
      ],
      color: 'from-purple-500 to-pink-500',
      hoverColor: 'hover:from-purple-600 hover:to-pink-600',
      textColor: 'text-purple-400',
      popular: true
    },
    {
      name: 'Enterprise Pack',
      icon: Crown,
      credits: 500,
      price: 415,
      pricePerTest: 0.83,
      description: 'For power users who need it all',
      features: [
        '500 test credits',
        '$0.83 per test',
        'Enterprise queue analysis',
        '24/7 Premium support',
        'Custom reporting',
        'Advanced integrations',
        'Dedicated account manager',
        'Custom development',
        'SLA guarantee'
      ],
      color: 'from-indigo-500 to-violet-500',
      hoverColor: 'hover:from-indigo-600 hover:to-violet-600',
      textColor: 'text-indigo-400'
    }
  ];
  return (
    <div className="min-h-screen bg-zinc-900 text-white relative overflow-hidden">
      <AnimatedBackground />
      <Navigation
        isLoggedIn={true}
        onSignOut={onSignOut}
        credits={credits}
        userName={userName}
        isAdmin={isAdmin}
        onRefresh={onRefresh}
      />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-emerald-400 to-blue-500 bg-clip-text text-transparent">
            Choose Your Credit Pack
          </h2>
          <p className="text-zinc-400 text-lg">
            Purchase credits in bulk and use them whenever you need
          </p>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          {plans.map((plan) => (
            <div
              key={plan.name}
              className={`relative rounded-2xl backdrop-blur-xl bg-zinc-900/50 p-8 border border-zinc-800 
                transform transition-all duration-300 hover:scale-105 hover:shadow-xl
                ${plan.popular ? 'ring-2 ring-emerald-500' : ''}`}
            >
              {plan.popular && (
                <div className="absolute top-0 right-8 transform -translate-y-1/2">
                  <span className="bg-gradient-to-r from-emerald-500 to-cyan-500 text-white px-3 py-1 rounded-full text-sm font-medium">
                    Most Popular
                  </span>
                </div>
              )}
              
              <div className={`w-12 h-12 rounded-full ${plan.color} flex items-center justify-center mb-4`}>
                <plan.icon className="w-6 h-6 text-white" />
              </div>
              
              <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>
              <div className="mb-4">
                <span className="text-4xl font-bold">${plan.price}</span>
                <div className="text-zinc-400 text-sm">
                  ${plan.pricePerTest} per test
                </div>
              </div>
              <p className="text-zinc-400 mb-6">{plan.description}</p>
              
              <ul className="space-y-3 mb-8">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex items-center">
                    <CheckIcon className="w-5 h-5 text-emerald-500 mr-2" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
              
              <button
                className={`w-full py-3 px-6 rounded-lg font-medium transition-all duration-300
                  bg-gradient-to-r ${plan.color} text-white
                  ${plan.hoverColor} transform hover:-translate-y-1`}
                onClick={() => {
                  if (userName) {
                    window.location.href = 'https://venmo.com/u/linelogic';
                  } else {
                    navigate('/signin');
                  }
                }}
              >
                {userName ? 'Purchase Credits' : 'Sign In to Purchase'}
              </button>
            </div>
          ))}
        </div>
        <div className="text-center bg-gradient-to-r from-zinc-900 to-zinc-800 rounded-2xl p-8 backdrop-blur-xl border border-zinc-800">
          <div className="flex items-center justify-center mb-4">
            <Heart className="w-8 h-8 text-pink-500 mr-2" />
            <h3 className="text-2xl font-bold">Need More Credits?</h3>
          </div>
          <p className="text-zinc-400 mb-6">
            Contact us for special monthly rates and custom volume discounts
          </p>
          <button
            className="bg-gradient-to-r from-pink-500 to-purple-500 text-white px-8 py-3 rounded-lg font-medium transition-all duration-300 hover:from-pink-600 hover:to-purple-600 transform hover:-translate-y-1"
            onClick={() => {
              if (userName) {
                window.location.href = 'mailto:support@linelogic.dev';
              } else {
                navigate('/signin');
              }
            }}
          >
            {userName ? 'Contact Sales' : 'Sign In to Contact Sales'}
          </button>
        </div>
      </div>
    </div>
  );
};
export default PricingPage;