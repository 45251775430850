import React, { useState, useEffect } from 'react';
import AnimatedDivider from './ui/AnimatedDivider';
import AnimatedBackground from './ui/AnimatedBackground';
import { LineChart, Clock, Shield } from 'lucide-react';

const LandingPage: React.FC = () => {
  return (
    <div className="relative min-h-screen">
      <div className="fixed inset-0 w-full h-full">
        <AnimatedBackground lineColor="green" className="bg-gradient-to-b from-navy via-dark-navy to-navy" />
      </div>
      
      <div className="relative z-10">
        {/* Hero Section */}
        <section className="min-h-screen flex items-center">
          <div className="max-w-7xl mx-auto px-4 py-20 w-full">
            <div className="relative z-10 max-w-3xl">
              <div className="inline-flex items-center space-x-2 px-4 py-2 rounded-full bg-emerald-green/10 border border-emerald-green/20 mb-8">
                <span className="relative flex h-2 w-2">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-emerald-green opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-2 w-2 bg-emerald-green"></span>
                </span>
                <span className="text-emerald-green font-medium font-mono">
                  <LiveQueueMetric />
                </span>
              </div>

              <h1 className="text-6xl lg:text-7xl font-bold leading-tight mb-8">
                <span className="text-white">Stop Wasting Time</span>
                <div className="relative">
                  <span className="bg-gradient-to-r from-emerald-green via-blue-500 to-purple-500 text-transparent bg-clip-text">
                    Test Smarter
                  </span>
                  <div className="absolute -bottom-4 left-0 right-0 h-1.5 bg-gradient-to-r from-emerald-green via-blue-500 to-purple-500 opacity-50 blur-sm"></div>
                </div>
              </h1>

              <p className="text-xl text-gray-400 mb-12 leading-relaxed">
                Tired of flying blind in queues? LineLogic gives you the edge with real-time queue analysis and account monitoring. Know exactly what's working and what isn't.
              </p>

              <div className="flex flex-wrap gap-6">
                <a
                  href="/signin"
                  className="group relative px-8 py-4 bg-emerald-green text-white rounded-lg overflow-hidden transition-transform hover:scale-105"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-emerald-green via-blue-500 to-purple-500 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                  <span className="relative flex items-center">
                    Start Testing Now
                  </span>
                </a>

                <a
                  href="/pricing"
                  className="px-8 py-4 border border-emerald-green/20 text-white rounded-lg hover:bg-emerald-green/5 transition-all hover:border-emerald-green"
                >
                  See Membership Options
                </a>
              </div>
            </div>
          </div>
        </section>

        <AnimatedDivider />

        {/* Features Section */}
        <section className="relative py-32">
          <div className="absolute inset-0 bg-gradient-to-b from-black/50 via-slate-900/50 to-black/50"></div>
          <div className="relative max-w-7xl mx-auto px-4">
            <div className="text-center mb-20">
              <h2 className="text-5xl font-bold mb-6">
                <span className="bg-gradient-to-r from-emerald-green to-blue-500 text-transparent bg-clip-text">
                  Built For Serious Brokers
                </span>
              </h2>
              <p className="text-xl text-gray-400 max-w-2xl mx-auto">
                Stop losing money on inefficient testing. Get the data you need to make smarter decisions.
              </p>
            </div>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  title: 'Queue Power Score™',
                  description: 'Know exactly how your accounts are performing in real-time across different events',
                  icon: LineChart
                },
                {
                  title: 'Wait Time Analysis',
                  description: 'Stop guessing about queue times. Get precise data on optimal entry timing',
                  icon: Clock
                },
                {
                  title: 'Account Health Tracking',
                  description: 'Monitor your accounts and get early warnings about potential issues',
                  icon: Shield
                },
              ].map((feature, index) => (
                <div
                  key={index}
                  className="group relative p-8 rounded-2xl bg-gradient-to-b from-slate-800/50 to-slate-900/50 border border-slate-700/50 hover:border-emerald-green/50 transition-all duration-500 shadow-lg transform hover:-translate-y-2 hover:shadow-xl"
                >
                  <div className="absolute inset-0 bg-gradient-to-b from-emerald-green/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500 rounded-2xl"></div>
                  <div className="relative">
                    <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-emerald-green to-blue-500 flex items-center justify-center mb-6 text-white">
                      {React.createElement(feature.icon, { size: 24 })}
                    </div>
                    <h3 className="text-xl font-bold text-white mb-4">
                      {feature.title}
                    </h3>
                    <p className="text-gray-400 leading-relaxed">
                      {feature.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Final CTA */}
        <section className="relative py-32">
          <div className="max-w-4xl mx-auto px-4 text-center">
            <h2 className="text-5xl font-bold mb-16">
              <span className="text-white">Ready To </span>
              <span className="bg-gradient-to-r from-emerald-green to-blue-500 text-transparent bg-clip-text">
                Step Up Your Game?
              </span>
            </h2>

            <div className="flex flex-wrap justify-center gap-6">
              <a
                href="/signup"
                className="group relative px-8 py-4 bg-emerald-green text-white rounded-lg overflow-hidden transition-transform hover:scale-105"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-emerald-green via-blue-500 to-purple-500 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                <span className="relative flex items-center">
                  Start Testing Now
                </span>
              </a>

              <a
                href="/contact"
                className="px-8 py-4 border border-emerald-green/20 text-white rounded-lg hover:bg-emerald-green/5 transition-all hover:border-emerald-green"
              >
                Talk to an Expert
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

// Live queue metric component
const LiveQueueMetric: React.FC = () => {
  const [value, setValue] = useState('47s');

  useEffect(() => {
    const interval = setInterval(() => {
      const times = ['43s', '45s', '47s', '42s', '48s'];
      setValue(times[Math.floor(Math.random() * times.length)]);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <span className="animate-glitch">
      Average Queue Time: {value}
    </span>
  );
};

export default LandingPage;