import React, { useState, useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { 
  BarChart2, 
  Info,
  Activity,
  Target,
  Server,
  Zap,
  Bot
} from 'lucide-react';
import AnimatedBackground from './ui/AnimatedBackground';
import { motion } from 'framer-motion';

interface TestResult {
  account: string;
  estimatedQueueStrength: number;
  queuePower: number;
  marketInfluence: number;
  systemOptimization: number;
  queueHealth: number;
  positionStrength: number;
  estimatedPosition: number;
  responseTime: number;
  industryTrends: {
    averageQueueTime: number;
    ticketDemandIndex: number;
  };
  technologyScore: number;
  timestamp: Date;
  failed?: boolean;
  error?: string;
}

interface ResultsPageProps {
  userId: string;
}

const ResultsPage: React.FC<ResultsPageProps> = ({ userId }) => {
  const location = useLocation();
  const results = location.state?.results;
  const [activeResult, setActiveResult] = useState<TestResult | null>(null);
  const [activeTooltip, setActiveTooltip] = useState<string | null>(null);
  const [aiSummary, setAiSummary] = useState<string>('');
  const [isLoadingAI, setIsLoadingAI] = useState(true);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    console.log('Results page mounted with state:', location.state);
    if (!results) {
      console.log('No results found in state');
      return;
    }

    if (Array.isArray(results)) {
      console.log('Setting active result from array:', results[0]);
      setActiveResult(results[0]);
    } else {
      console.log('Setting active result:', results);
      setActiveResult(results);
    }

    setShowContent(true);
  }, [results, location.state]);

  useEffect(() => {
    if (!activeResult || activeResult.failed) {
      setIsLoadingAI(false);
      return;
    }

    const generateSummary = async () => {
      try {
        setIsLoadingAI(true);
        const response = await fetch('/.netlify/functions/generateAISummary', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            result: activeResult,
            userId: userId
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to generate AI summary');
        }

        const data = await response.json();
        setAiSummary(data.summary);
      } catch (error) {
        console.error('Error generating AI summary:', error);
        setAiSummary('Unable to generate AI summary at this time.');
      } finally {
        setIsLoadingAI(false);
      }
    };

    generateSummary();
  }, [activeResult, userId]);

  if (!location.state || !results) {
    console.log('No state or results, redirecting to dashboard');
    return <Navigate to="/dashboard" replace />;
  }

  if (!activeResult) {
    console.log('No active result, showing loading');
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-emerald-500" />
      </div>
    );
  }

  const getStrengthColor = (value: number, max: number = 100) => {
    const percentage = (value / max) * 100;
    if (percentage >= 85) return 'text-emerald-500';
    if (percentage >= 70) return 'text-blue-500';
    if (percentage >= 50) return 'text-yellow-500';
    return 'text-red-500';
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.15
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: { 
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const numberAnimation = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: { 
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const hoverAnimation = {
    scale: 1.02,
    transition: {
      duration: 0.2,
      ease: "easeOut"
    }
  };

  const Tooltip = ({ id, content }: { id: string; content: string }) => (
    <motion.div
      initial={{ opacity: 0, y: 5 }}
      animate={{ 
        opacity: activeTooltip === id ? 1 : 0,
        y: activeTooltip === id ? 0 : 5,
        zIndex: activeTooltip === id ? 50 : -1
      }}
      transition={{ duration: 0.2 }}
      className={`fixed transform -translate-x-1/2 left-1/2 top-4 p-4 text-sm bg-gray-800 text-white border border-gray-700 rounded-lg shadow-xl max-w-sm`}
    >
      {content}
    </motion.div>
  );

  const MetricCard = ({
    label,
    value,
    tooltipContent,
    id,
    icon,
    subValue,
    highlight = false
  }: {
    label: string;
    value: React.ReactNode;
    tooltipContent: string;
    id: string;
    icon: React.ReactNode;
    subValue?: React.ReactNode;
    highlight?: boolean;
  }) => (
    <motion.div
      variants={itemVariants}
      whileHover={hoverAnimation}
      className={`relative p-6 bg-white/95 backdrop-blur rounded-xl shadow-md transition-all duration-200 ${
        highlight ? 'ring-2 ring-emerald-500' : ''
      }`}
    >
      <div className="flex items-start justify-between space-y-4">
        <div className="w-full">
          <div className="flex items-center gap-3 mb-3">
            <motion.div 
              className="text-emerald-600"
              animate={highlight ? { scale: 1.02 } : {}}
            >
              {icon}
            </motion.div>
            <h3 className="font-semibold text-gray-800">{label}</h3>
            <div 
              className="ml-auto cursor-help"
              onMouseEnter={() => setActiveTooltip(id)}
              onMouseLeave={() => setActiveTooltip(null)}
            >
              <Info size={16} className="text-gray-400 hover:text-gray-600 transition-colors" />
            </div>
          </div>
          <motion.div 
            className="text-3xl font-bold tracking-tight"
            variants={numberAnimation}
          >
            {value}
          </motion.div>
          {subValue && (
            <motion.div 
              variants={numberAnimation}
              className="mt-3 text-sm text-gray-600 font-medium"
            >
              {subValue}
            </motion.div>
          )}
        </div>
      </div>
      <Tooltip id={id} content={tooltipContent} />
    </motion.div>
  );

  const StatItem = ({ label, value, colorClass }: { label: string; value: React.ReactNode; colorClass: string }) => (
    <motion.li 
      variants={itemVariants}
      className="flex items-center justify-between pb-3 border-b border-gray-100"
    >
      <span className="text-gray-700 font-medium">{label}</span>
      <motion.span 
        variants={numberAnimation}
        className={`font-bold ${colorClass}`}
      >
        {value}
      </motion.span>
    </motion.li>
  );

  return (
    <div className="min-h-screen relative">
      <div className="absolute inset-0 overflow-hidden">
        <AnimatedBackground lineColor="subtle-green" />
      </div>
      <motion.div
        className="relative z-10 container mx-auto px-6 py-12 space-y-10"
        initial="hidden"
        animate={showContent ? "visible" : "hidden"}
        variants={containerVariants}
      >
        {Array.isArray(results) && results.length > 1 && (
          <motion.div variants={itemVariants} className="bg-white/95 backdrop-blur rounded-2xl p-6 shadow-lg">
            <h2 className="text-xl font-bold text-gray-800 mb-4">Test Results</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {results.map((result, index) => (
                <motion.button
                  key={result.account}
                  onClick={() => setActiveResult(result)}
                  className={`p-4 rounded-lg transition-all ${
                    activeResult === result
                      ? 'bg-emerald-500 text-white shadow-lg scale-105'
                      : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                  }`}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <p className="font-medium truncate">{result.account}</p>
                  {!result.failed && (
                    <p className="text-sm opacity-80">
                      Queue Power: {result.queuePower}/100
                    </p>
                  )}
                  {result.failed && (
                    <p className="text-sm text-red-500">
                      Failed: {result.error}
                    </p>
                  )}
                </motion.button>
              ))}
            </div>
          </motion.div>
        )}

        {/* Header Section */}
        <motion.div 
          variants={itemVariants}
          className="bg-white/95 backdrop-blur rounded-2xl p-8 shadow-lg"
        >
          <h1 className="text-3xl font-bold text-gray-800 mb-6">Queue Analysis Results</h1>
          <div className="text-gray-700 text-lg">
            {isLoadingAI ? (
              <motion.div 
                className="flex items-center gap-3 text-emerald-600"
                animate={{ opacity: [0.5, 1, 0.5] }}
                transition={{ duration: 1.5, repeat: Infinity }}
              >
                <Bot className="animate-spin" />
                <span>Analyzing your queue data...</span>
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                {aiSummary}
              </motion.div>
            )}
          </div>
        </motion.div>

        {/* Main Metrics Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <MetricCard
            label="Queue Power Rating"
            value={
              <span className={getStrengthColor(activeResult.queuePower)}>
                {activeResult.queuePower}/100
              </span>
            }
            tooltipContent="Proprietary queue strength score combining all performance factors. Higher scores indicate better potential queue positions."
            id="queuePower"
            icon={<Activity size={24} />}
            subValue={`Health: ${activeResult.queueHealth}/100`}
            highlight={activeResult.queuePower >= 85}
          />
          <MetricCard
            label="Est. Position"
            value={
              <span className={getStrengthColor(activeResult.positionStrength)}>
                #{activeResult.estimatedPosition}
              </span>
            }
            tooltipContent="Projected queue position based on current metrics and system performance."
            id="position"
            icon={<Target size={24} />}
            subValue={`Strength: ${activeResult.positionStrength}/100`}
            highlight={activeResult.estimatedPosition <= 3}
          />
          <MetricCard
            label="System Performance"
            value={
              <span className={getStrengthColor(activeResult.systemOptimization)}>
                {activeResult.systemOptimization}/100
              </span>
            }
            tooltipContent="Overall system efficiency score. Factors in response time and technical performance."
            id="systemOptimization"
            icon={<Server size={24} />}
            subValue={`Response: ${activeResult.responseTime}ms`}
            highlight={activeResult.systemOptimization >= 85}
          />
        </div>

        {/* Details Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Performance Metrics Panel */}
          <motion.div 
            variants={itemVariants}
            className="bg-white/95 backdrop-blur rounded-2xl p-8 shadow-lg"
          >
            <h3 className="text-xl font-bold text-gray-800 mb-6 flex items-center gap-3">
              <motion.div
                animate={{ scale: 1.02 }}
                className="text-emerald-500"
              >
                <Zap size={24} />
              </motion.div>
              Performance Metrics
            </h3>
            <motion.ul 
              variants={containerVariants}
              className="space-y-5"
            >
              <StatItem 
                label="Market Influence"
                value={`${activeResult.marketInfluence}/100`}
                colorClass={getStrengthColor(activeResult.marketInfluence)}
              />
              <StatItem 
                label="Response Time"
                value={`${activeResult.responseTime}ms`}
                colorClass={
                  activeResult.responseTime < 500 ? 'text-emerald-500' : 
                  activeResult.responseTime < 800 ? 'text-blue-500' : 
                  'text-yellow-500'
                }
              />
              <StatItem 
                label="Technology Score"
                value={`${activeResult.technologyScore}/100`}
                colorClass={getStrengthColor(activeResult.technologyScore)}
              />
            </motion.ul>
          </motion.div>

          {/* Queue Stats Panel */}
          <motion.div 
            variants={itemVariants}
            className="bg-white/95 backdrop-blur rounded-2xl p-8 shadow-lg"
          >
            <h3 className="text-xl font-bold text-gray-800 mb-6 flex items-center gap-3">
              <motion.div
                animate={{ scale: 1.02 }}
                className="text-emerald-500"
              >
                <BarChart2 size={24} />
              </motion.div>
              Queue Stats
            </h3>
            <motion.ul 
              variants={containerVariants}
              className="space-y-5"
            >
              <StatItem 
                label="Avg Queue Time"
                value={`${activeResult.industryTrends.averageQueueTime} mins`}
                colorClass="text-gray-800"
              />
              <StatItem 
                label="Demand Index"
                value={`${activeResult.industryTrends.ticketDemandIndex}/10`}
                colorClass={getStrengthColor(activeResult.industryTrends.ticketDemandIndex * 10)}
              />
              <StatItem 
                label="Queue Health"
                value={`${activeResult.queueHealth}/100`}
                colorClass={getStrengthColor(activeResult.queueHealth)}
              />
            </motion.ul>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default ResultsPage;