import React, { useEffect, useState } from 'react';
import { BarChart3, Download } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface TestResult {
  id: string;
  account: string;
  queuePower: number;
  marketInfluence: number;
  systemOptimization: number;
  queueHealth: number;
  positionStrength: number;
  estimatedPosition: number;
  responseTime: number;
  technologyScore: number;
  industryTrends: {
    averageQueueTime: number;
    ticketDemandIndex: number;
  };
  timestamp: string;
}

interface DashboardProps {
  userId: string;
  credits: number;
  isAdmin: boolean;
}

const Dashboard: React.FC<DashboardProps> = ({ userId, credits, isAdmin }) => {
  const [testHistory, setTestHistory] = useState<TestResult[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTestHistory = async () => {
      if (!userId) {
        setError('User ID is missing. Please sign in again.');
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(`/.netlify/functions/getTestHistory?userId=${userId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (Array.isArray(data.testHistory)) {
          setTestHistory(data.testHistory);
        } else {
          console.error('Unexpected data structure:', data);
          setError('Unexpected data structure in response');
        }
      } catch (err) {
        console.error('Error fetching test history:', err);
        setError('Failed to load test history. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchTestHistory();
  }, [userId]);

  const convertToCSV = (data: TestResult[]) => {
    // Define CSV headers
    const headers = [
      'Account',
      'Queue Power',
      'Market Influence',
      'System Optimization',
      'Queue Health',
      'Position Strength',
      'Estimated Position',
      'Response Time (ms)',
      'Technology Score',
      'Average Queue Time (mins)',
      'Ticket Demand Index',
      'Timestamp'
    ].join(',');

    // Convert each test result to CSV row
    const rows = data.map(test => [
      test.account,
      test.queuePower,
      test.marketInfluence,
      test.systemOptimization,
      test.queueHealth,
      test.positionStrength,
      test.estimatedPosition,
      test.responseTime,
      test.technologyScore,
      test.industryTrends.averageQueueTime,
      test.industryTrends.ticketDemandIndex,
      new Date(test.timestamp).toLocaleString()
    ].join(','));

    return [headers, ...rows].join('\n');
  };

  const handleExport = () => {
    try {
      const csv = convertToCSV(testHistory);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      
      link.setAttribute('href', url);
      link.setAttribute('download', `queue_test_history_${new Date().toISOString().split('T')[0]}.csv`);
      link.style.visibility = 'hidden';
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error('Error exporting CSV:', err);
      setError('Failed to export test history. Please try again.');
    }
  };

  const handleViewResults = (test: TestResult) => {
    navigate('/results', { state: { results: test } });
  };

  if (isLoading) {
    return <div className="text-center py-8">Loading test history...</div>;
  }

  if (error) {
    return (
      <div className="text-center py-8">
        <p className="text-red-500 mb-4">{error}</p>
        <button
          onClick={() => window.location.reload()}
          className="bg-emerald-green text-white py-2 px-4 rounded hover:bg-midnight-blue transition duration-300"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto">
      <div className="mb-4 flex justify-between items-center">
        <div>
          <span className="text-emerald-green font-semibold">
            Credits remaining: {credits}
          </span>
          {isAdmin && <span className="ml-4 text-midnight-blue">(Admin)</span>}
        </div>
        {testHistory.length > 0 && (
          <button
            onClick={handleExport}
            className="flex items-center gap-2 bg-emerald-green text-white py-2 px-4 rounded hover:bg-midnight-blue transition duration-300"
          >
            <Download size={18} />
            Export to CSV
          </button>
        )}
      </div>

      <h2 className="text-4xl font-bold mb-6 text-midnight-blue">Your Test History</h2>
      {testHistory.length === 0 ? (
        <p className="text-center text-graphite-grey">No tests have been performed yet.</p>
      ) : (
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <table className="w-full">
            <thead className="bg-midnight-blue text-soft-ivory">
              <tr>
                <th className="px-6 py-3 text-left">Account</th>
                <th className="px-6 py-3 text-left">Queue Power</th>
                <th className="px-6 py-3 text-left">Date Tested</th>
                <th className="px-6 py-3 text-left">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {testHistory.map((test) => (
                <tr key={test.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4">{test.account}</td>
                  <td className="px-6 py-4">{test.queuePower}</td>
                  <td className="px-6 py-4">
                    {new Date(test.timestamp).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() => handleViewResults(test)}
                      className="text-emerald-green hover:text-midnight-blue flex items-center"
                    >
                      <BarChart3 className="w-4 h-4 mr-2" />
                      View Results
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Dashboard;