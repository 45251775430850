import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  credits?: number;
}

export const Button: React.FC<ButtonProps> = ({ children, className, credits, ...props }) => {
  return (
    <button
      className={`py-2 px-4 rounded-lg transition duration-300 focus:outline-none bg-emerald-green text-white ${className}`}
      {...props}
    >
      {credits !== undefined && (
        <span className="mr-2">Credits: {credits}</span>
      )}
      {children}
    </button>
  );
};
