import React, { useEffect, useRef } from 'react'

interface BaseProps {
  children?: React.ReactNode;
  className?: string;
  gradientStyle?: string;
}

interface AnimatedBackgroundProps extends BaseProps {
  lineColor?: 'green' | 'subtle-green';
}

class Node {
  x: number
  y: number
  vx: number
  vy: number
  canvas: HTMLCanvasElement

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas
    this.x = Math.random() * this.canvas.width
    this.y = Math.random() * this.canvas.height
    this.vx = (Math.random() - 0.5) * 2
    this.vy = (Math.random() - 0.5) * 2
  }

  update() {
    this.vx *= 0.99
    this.vy *= 0.99
    
    if (Math.abs(this.vx) < 0.5) this.vx = (Math.random() - 0.5) * 2
    if (Math.abs(this.vy) < 0.5) this.vy = (Math.random() - 0.5) * 2
    
    this.x += this.vx
    this.y += this.vy

    if (this.x > this.canvas.width) {
      this.x = 0
    } else if (this.x < 0) {
      this.x = this.canvas.width
    }

    if (this.y > this.canvas.height) {
      this.y = 0
    } else if (this.y < 0) {
      this.y = this.canvas.height
    }
  }
}

const AnimatedBackground: React.FC<AnimatedBackgroundProps> = ({
  children,
  className = '',
  lineColor = 'green',
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const animationFrameId = useRef<number>()
  const nodesRef = useRef<Node[]>([])
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const container = containerRef.current
    if (!canvas || !container) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    const handleResize = () => {
      if (!canvas || !container) return
      const { width, height } = container.getBoundingClientRect()
      
      // Set canvas size to match container size
      canvas.width = width
      canvas.height = height
      
      // Scale for high DPI displays
      const dpr = window.devicePixelRatio || 1
      canvas.width = width * dpr
      canvas.height = height * dpr
      ctx.scale(dpr, dpr)
      
      // Reset nodes with new dimensions
      nodesRef.current = Array.from(
        { length: 25 }, 
        () => new Node(canvas)
      )
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    const animate = () => {
      if (!ctx || !canvas) return

      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.fillStyle = lineColor === 'subtle-green' 
        ? 'rgba(0, 255, 157, 0.5)'
        : 'rgba(0, 255, 157, 0.8)'

      const nodes = nodesRef.current
      
      nodes.forEach((node) => {
        node.update()
        
        ctx.beginPath()
        ctx.arc(node.x, node.y, 2, 0, Math.PI * 2)
        ctx.fill()

        nodes.forEach((neighbor) => {
          if (node === neighbor) return
          
          const dx = node.x - neighbor.x
          const dy = node.y - neighbor.y
          const distance = Math.sqrt(dx * dx + dy * dy)
          
          if (distance < 120) {
            ctx.strokeStyle = lineColor === 'subtle-green'
              ? `rgba(0, 255, 157, ${0.15 * (1 - distance / 120)})`
              : `rgba(0, 255, 157, ${0.25 * (1 - distance / 120)})`
            ctx.lineWidth = 0.8
            ctx.beginPath()
            ctx.moveTo(node.x, node.y)
            ctx.lineTo(neighbor.x, neighbor.y)
            ctx.stroke()
          }
        })
      })

      animationFrameId.current = requestAnimationFrame(animate)
    }

    animate()

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current)
      }
      window.removeEventListener('resize', handleResize)
    }
  }, [lineColor])

  return (
    <div ref={containerRef} className={`absolute inset-0 w-full h-full ${className}`}>
      <canvas
        ref={canvasRef}
        className="absolute inset-0 w-full h-full"
        style={{ display: 'block' }}
      />
      {children}
    </div>
  )
}

export default AnimatedBackground