import React, { useState, useEffect } from 'react';
import { doc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { Search, Clock, CreditCard, Filter } from 'lucide-react';
import { Alert, AlertDescription } from './ui/alert';

interface CreditHistoryEntry {
  amount: number;
  timestamp: string | Date;
  type: 'add' | 'use';
}

interface User {
  id: string;
  email: string;
  name: string;
  credits: number;
  lastLogin: string | null;
  lastLoginAt: string | null;
  creditHistory?: CreditHistoryEntry[];
}

const AdminPage: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [creditsToAdd, setCreditsToAdd] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [message, setMessage] = useState<{ type: 'success' | 'error'; text: string } | null>(null);
  const [activeTab, setActiveTab] = useState<'overview' | 'details'>('overview');
  const [sortConfig, setSortConfig] = useState<{ field: keyof User; direction: 'asc' | 'desc' }>({
    field: 'email',
    direction: 'asc'
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await fetch('/.netlify/functions/getUsers');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (Array.isArray(data.users)) {
          setUsers(data.users);
          setFilteredUsers(data.users);
        } else {
          console.error('Unexpected data structure:', data);
          setMessage({ type: 'error', text: 'Unexpected data structure in response' });
        }
      } catch (error) {
        console.error('Error fetching users:', error);
        setMessage({ type: 'error', text: 'Failed to fetch users. Please try again.' });
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const filtered = users.filter(user =>
      user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (user.name && user.name.toLowerCase().includes(searchQuery.toLowerCase()))
    );
    setFilteredUsers(filtered);
  }, [searchQuery, users]);

  const handleAddCredits = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedUser || creditsToAdd <= 0) {
      setMessage({ type: 'error', text: 'Please select a user and enter a valid number of credits.' });
      return;
    }

    try {
      const userRef = doc(db, 'Users', selectedUser);
      const timestamp = new Date().toISOString();
      
      const selectedUserData = users.find(u => u.id === selectedUser);
      if (!selectedUserData) {
        throw new Error('User not found');
      }

      await updateDoc(userRef, {
        credits: increment(creditsToAdd),
        creditHistory: [
          ...(selectedUserData.creditHistory || []),
          {
            amount: creditsToAdd,
            timestamp,
            type: 'add'
          }
        ]
      });

      setMessage({ type: 'success', text: `Successfully added ${creditsToAdd} credits to the selected user.` });
      setCreditsToAdd(0);
      setSelectedUser('');
    } catch (error) {
      console.error('Error adding credits:', error);
      setMessage({ type: 'error', text: 'Error adding credits. Please try again.' });
    }
  };

  const handleSort = (field: keyof User) => {
    setSortConfig(prev => ({
      field,
      direction: prev.field === field && prev.direction === 'asc' ? 'desc' : 'asc'
    }));

    const sorted = [...filteredUsers].sort((a, b) => {
      if (sortConfig.direction === 'asc') {
        return String(a[field]).localeCompare(String(b[field]));
      }
      return String(b[field]).localeCompare(String(a[field]));
    });
    setFilteredUsers(sorted);
  };

  const formatDate = (date: Date | string | null | undefined): string => {
    if (!date) return 'Never';
    const d = typeof date === 'string' ? new Date(date) : date;
    return d.toLocaleString();
  };

  const UserOverview = () => (
    <div className="overflow-x-auto">
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-50">
            <th className="p-4 text-left border-b cursor-pointer hover:bg-gray-100" 
                onClick={() => handleSort('email')}>
              <div className="flex items-center gap-2">
                Email
                <Filter className="w-4 h-4" />
              </div>
            </th>
            <th className="p-4 text-left border-b cursor-pointer hover:bg-gray-100" 
                onClick={() => handleSort('name')}>
              <div className="flex items-center gap-2">
                Name
                <Filter className="w-4 h-4" />
              </div>
            </th>
            <th className="p-4 text-left border-b cursor-pointer hover:bg-gray-100"
                onClick={() => handleSort('credits')}>
              <div className="flex items-center gap-2">
                Credits
                <Filter className="w-4 h-4" />
              </div>
            </th>
            <th className="p-4 text-left border-b cursor-pointer hover:bg-gray-100"
                onClick={() => handleSort('lastLogin')}>
              <div className="flex items-center gap-2">
                Last Login
                <Filter className="w-4 h-4" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map(user => (
            <tr 
              key={user.id}
              className="hover:bg-gray-50 cursor-pointer"
              onClick={() => {
                setSelectedUser(user.id);
                setActiveTab('details');
              }}
            >
              <td className="p-4 border-b">{user.email}</td>
              <td className="p-4 border-b">{user.name || 'N/A'}</td>
              <td className="p-4 border-b">{user.credits}</td>
              <td className="p-4 border-b">
                <div className="flex items-center gap-2">
                  <Clock className="w-4 h-4" />
                  {formatDate(user.lastLogin)}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const UserDetails = () => {
    const user = users.find(u => u.id === selectedUser);
    if (!user) return null;

    return (
      <div className="space-y-6">
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-4">User Details</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-gray-600">Email</p>
              <p className="font-medium">{user.email}</p>
            </div>
            <div>
              <p className="text-gray-600">Name</p>
              <p className="font-medium">{user.name}</p>
            </div>
            <div>
              <p className="text-gray-600">Current Credits</p>
              <p className="font-medium">{user.credits}</p>
            </div>
            <div>
              <p className="text-gray-600">Last Login</p>
              <p className="font-medium">{formatDate(user.lastLogin)}</p>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-4">Credit History</h3>
          <div className="space-y-4">
            {user.creditHistory?.map((entry, index) => (
              <div key={index} className="flex items-center justify-between p-3 bg-gray-50 rounded">
                <div className="flex items-center gap-3">
                  <CreditCard className="w-5 h-5" />
                  <span className={entry.type === 'add' ? 'text-green-600' : 'text-red-600'}>
                    {entry.type === 'add' ? '+' : '-'}{entry.amount} credits
                  </span>
                </div>
                <span className="text-gray-500">
                  {formatDate(entry.timestamp)}
                </span>
              </div>
            ))}
          </div>
        </div>

        <form onSubmit={handleAddCredits} className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-4">Add Credits</h3>
          <div className="space-y-4">
            <div>
              <label htmlFor="credits-input" className="block text-sm font-medium text-gray-700">
                Credits to Add
              </label>
              <input
                id="credits-input"
                type="number"
                value={creditsToAdd}
                onChange={(e) => setCreditsToAdd(parseInt(e.target.value))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                min="1"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-150"
            >
              Add Credits
            </button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="max-w-6xl mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold">Admin Dashboard</h2>
        <div className="flex items-center gap-4">
          <div className="relative">
            <Search className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search by email or name..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow">
        <div className="border-b">
          <nav className="flex">
            <button
              className={`px-6 py-4 text-sm font-medium ${
                activeTab === 'overview'
                  ? 'border-b-2 border-blue-500 text-blue-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
              onClick={() => setActiveTab('overview')}
            >
              Overview
            </button>
            {selectedUser && (
              <button
                className={`px-6 py-4 text-sm font-medium ${
                  activeTab === 'details'
                    ? 'border-b-2 border-blue-500 text-blue-600'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
                onClick={() => setActiveTab('details')}
              >
                User Details
              </button>
            )}
          </nav>
        </div>

        <div className="p-6">
          {message && (
            <Alert className={`mb-4 ${message.type === 'error' ? 'bg-red-50' : 'bg-green-50'}`}>
              <AlertDescription>
                {message.text}
              </AlertDescription>
            </Alert>
          )}

          {loading ? (
            <p>Loading...</p>
          ) : (
            activeTab === 'overview' ? <UserOverview /> : <UserDetails />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;