import React from 'react';
import { Bot, Activity, Target } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

const LoadingScreen: React.FC = () => {
  const location = useLocation();
  const {} = location.state || {};

  const iconVariants = {
    animate: {
      scale: [1, 1.2, 1],
      rotate: [0, 360, 360],
      transition: {
        duration: 2,
        repeat: Infinity,
        ease: "easeInOut"
      }
    }
  };

  const textVariants = {
    animate: {
      opacity: [0.5, 1, 0.5],
      transition: {
        duration: 1.5,
        repeat: Infinity,
        ease: "easeInOut"
      }
    }
  };

  const progressVariants = {
    initial: { width: "0%" },
    animate: {
      width: "100%",
      transition: {
        duration: 2,
        repeat: Infinity,
        ease: "linear"
      }
    }
  };

  return (
    <div className="fixed inset-0 bg-white/[0.12] backdrop-blur-xl flex items-center justify-center z-50">
      <div className="max-w-md w-full mx-4">
        <motion.div 
          className="text-center space-y-6 bg-white/10 backdrop-blur-lg rounded-2xl p-8 border border-emerald-500/20 shadow-2xl"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex justify-center space-x-4">
            <motion.div
              variants={iconVariants}
              animate="animate"
              className="text-emerald-400"
            >
              <Bot size={40} />
            </motion.div>
            <motion.div
              variants={iconVariants}
              animate="animate"
              className="text-blue-400"
            >
              <Activity size={40} />
            </motion.div>
            <motion.div
              variants={iconVariants}
              animate="animate"
              className="text-emerald-400"
            >
              <Target size={40} />
            </motion.div>
          </div>

          <div className="space-y-3">
            <motion.h2 
              className="text-2xl font-bold bg-gradient-to-r from-emerald-400 to-blue-500 bg-clip-text text-transparent"
              variants={textVariants}
              animate="animate"
            >
              Analyzing Queue Performance
            </motion.h2>
            <p className="text-gray-400">Crunching numbers and optimizing results...</p>
          </div>

          <div className="relative h-1 bg-white/10 rounded-full overflow-hidden">
            <motion.div
              className="absolute inset-y-0 left-0 bg-gradient-to-r from-emerald-500 to-blue-500 rounded-full"
              variants={progressVariants}
              initial="initial"
              animate="animate"
            />
          </div>

          <div className="text-sm text-gray-400 space-y-2">
            <motion.p
              variants={textVariants}
              animate="animate"
            >
              Calculating queue metrics...
            </motion.p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default LoadingScreen;