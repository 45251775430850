// src/utils/loginTracker.ts
export const trackUserLogin = async (userId: string) => {
    try {
      const deviceInfo = {
        platform: navigator.platform,
        language: navigator.language,
        userAgent: navigator.userAgent,
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
  
      const response = await fetch('/.netlify/functions/trackLogin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId,
          deviceInfo
        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to track login');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error tracking login:', error);
      // Don't throw error as this is not critical functionality
      return null;
    }
  };