// src/components/ui/alert.tsx
import React from 'react';

interface AlertProps {
  className?: string;
  children: React.ReactNode;
}

interface AlertDescriptionProps {
  children: React.ReactNode;
}

export const Alert: React.FC<AlertProps> = ({ children, className = '' }) => {
  return (
    <div role="alert" className={`rounded-lg border p-4 ${className}`}>
      {children}
    </div>
  );
};

export const AlertDescription: React.FC<AlertDescriptionProps> = ({ children }) => {
  return <div className="mt-2 text-sm">{children}</div>;
};